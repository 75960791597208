.content-carousel {
    margin-top: 60px;
    width: 100%;
    height: 100%;
}

.content-carousel img {
    width: 100%;
    height: 100%;
    text-align: center;
}