.clients-container {
    margin: 80px 0 0 0;
    height: 280px;
    width: 100%;
}

.clients-container h2 {
    text-align: center;
    margin: 0 0 50px 0;
}

.clients-container h2::after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 4%;
    padding-top: 15px;
    border-bottom: 2px solid #f91942;
    transition: 0.3s ease-in-out;
    transform: scaleX(1);
}

.clients-container h2:hover::after {
    transform: scaleX(0.3);
}

.react-multi-carousel-list {
    padding-bottom: 75px !important;
}
