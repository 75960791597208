.functionality-content {
    margin: 100px 50px 0 0;
    width: 100%;
    height: 100%;
}

.functionality-content h2 {
    font-size: 27px;
    margin: 0;
    text-align: center;
}

/*Custom underline*/
.functionality-content h2::after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 5%;
    padding-top: 15px;
    border-bottom: 2px solid #f91942;
    transform: scaleX(1);
    transition: transform 0.3s ease-in-out;
}

.functionality-content h2:hover::after {
    transform: scaleX(0.3)
}

.wrapper {
    margin: 50px 330px 0 330px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.wrapper div {
    padding: 25px;
    margin: 10px;
    border-radius: 5px;
    width: 220px;
    height: 220px;
    text-align: center;
    box-shadow: 0 0 8px 8px rgb(0 0 0 / 5%);
    background-color: #FFFFFF;
    cursor: pointer;
    transition: transform 500ms;
    will-change: tansform;
}

.wrapper div:hover {
    transform: translateY(-10px);
}

.wrapper-content img {
    width: 54px;
    height: 54px;
}

.wrapper-content h4 {
    margin-top: 30px;
    font-size: 16px;
    color: #484848;
}

@media only screen and (max-width: 1660px) {
    .wrapper {margin: 50px 150px 0 150px}
}

@media only screen and (max-width: 800px) {
    .wrapper {margin: 50px 10px 10px}
}