.bottom-up {
    position: relative;
}

.bottom-up .bottom-up__svg-icon {
    animation: fadeInOut ease 1.1s forwards;
    position: fixed;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid #f91942;
    background-color: #f91942;
    cursor: pointer;
    color: white;
    bottom: 50px;
    right: 40px
}

@keyframes fadeInOut {
    0%{
        opacity:0;
    }
    100%{
        opacity:1;
    }
}

