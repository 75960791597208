* {
    box-sizing: border-box;
}

.header-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 67px;
    background-color: #FFFFFF;
    box-shadow: 0 0 12px 0 rgb(0 0 0 / 12%);
    z-index: 1010;
}

.header-container__navigation {
    position: absolute;
    bottom: 15px;
    left: 30%;
    font-size: 15px;
    color: #444444;
}

.header-container__navigation img {
    position: absolute;
    bottom: -5px;
    left: -250px;
    width: 210px;
    cursor: pointer;
}

.header-container__navigation span {
    padding: 10px 15px 10px 15px;
}

.header-container__navigation span:hover {
    color: #F91942;
    border-radius: 60px;
    background-color: rgba(243, 12, 12, 0.06);
    cursor: pointer;
}

.menu.MuiSvgIcon-root {
    width: 40px !important;
    height: 40px !important;
    cursor: pointer;
}

.menu {
    position: absolute;
    right: 20px;
    bottom: 10px;
    padding: 5px 5px 5px 5px;
    color: #5B5B5B;
    border-radius: 5px;
    background-color: #E5E5E5;
}

.open-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    background-color: #212121;
    transition: transform 0.6s ease;
    z-index: 1003;
}

.close-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    background-color: #212121;
    transition: transform 0.6s ease;
    transform: translateX(-100%);
    z-index: 1003;
}

.header-container__navigation-mobile img {
    position: absolute;
    top: 15px;
    left: 20px;
    width: 200px;
}

.header-container__navigation-mobile h4 {
    margin: 15px 0 15px 0;
    font-size: 12px;
    text-align: center;
    color: white;
}

.header-container__navigation-mobile hr {
    border-color: grey;
}

.header-container__navigation-mobile span {
    padding-left: 15px;
    font-size: 15px;
    text-align: center;
    color: white;
}

.header-container__navigation-mobile span:hover {
    color: #F91942;
    cursor: pointer;
}

.language-style span {
    cursor: pointer;
}

.header-language {
    position: absolute;
    top: 20px;
    right: 20px;
}

.language-style .language-style--active,
.language-style span:hover {
    font-weight: 700;
    color: white;
}

.header-language.language-style .language-style--active,
.header-language.language-style span:hover {
    color: black;
}

