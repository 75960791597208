.functionality-description-1 {
    display: flex;
    margin: 50px 0 0 0;
    border-top: 1px solid #eeeeee;
    padding: 0;
    align-items: center;
    position: relative;
}

.functionality-description-1__column-1 {
    flex: 0.4;
    height: 500px;
    background-image: url('../img/Untitled-2.svg');
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 1400px) {
    .functionality-description-1 {
        display: flex;
        flex-direction: column;
        padding: 0
    }

    .functionality-description-2 {
        display: flex;
        flex-direction: column;
    }

    .functionality-description-1__column-1 {
        background-image: url('../img/Untitled-2.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 700px;
    }

    .functionality-description-1__column-2 {
        margin-bottom: 20px;
    }

    .functionality-description-2__column-2 {
        background-image: url('../img/Untitled-3.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 930px;
    }
}

@media only screen and (max-width: 1150px) {
    .functionality-description-1__column-1 {
        background-image: url('../img/Untitled-2.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 550px;
    }

    .functionality-description-1__column-2 {
        margin-bottom: 20px;
    }

    .functionality-description-2__column-2 {
        background-image: url('../img/Untitled-3.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 750px;
    }
}

@media only screen and (max-width: 900px) {
    .functionality-description-1__column-1 {
        background-image: url('../img/Untitled-2.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 450px;
    }
    .functionality-description-2__column-2 {
        background-image: url('../img/Untitled-3.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 590px;
    }
}

@media only screen and (max-width: 750px) {
    .functionality-description-1__column-1 {
        background-image: url('../img/Untitled-2.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 390px;
    }
    .functionality-description-2__column-2 {
        background-image: url('../img/Untitled-3.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 490px;
    }
}

@media only screen and (max-width: 580px) {
    .functionality-description-1__column-1 {
        background-image: url('../img/Untitled-2.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 350px;
    }

    .functionality-description-2__column-2 {
        background-image: url('../img/Untitled-3.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 370px;
    }
}

@media only screen and (max-width: 400px) {
    .functionality-description-1__column-1 {
        background-image: url('../img/Untitled-2.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 280px;
    }
    .functionality-description-2__column-2 {
        background-image: url('../img/Untitled-3.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 210px;
    }
}

.functionality-description-1__column-2 {
    flex:0.6;
    text-align: center;
}

.functionality-description-1__column-2 h2 {
    margin: 0;
    font-size: 23px;
    text-align: center;
}

.functionality-description-1__column-2 h2::after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 9%;
    padding-top: 15px;
    border-bottom: 2px solid #f91942;
    transform: scaleX(1);
    transition: 0.3s ease-in-out;
}

.functionality-description-1__column-2 h2:hover::after {
    transform: scaleX(0.5);
}

.functionality-description-1__column-2 p {
    text-align: justify;
    font-size: 15px;
    margin: 20px 80px 0 80px;
    line-height: 21px;
}

/*.functionality-description-1__column-2 li {*/
/*    padding: 10px 0 4px 0;*/
/*}*/

.functionality-description-2 {
    display: flex;
    align-items: center;
}

.functionality-description-2__column-1 {
    flex: 0.6;
}

.functionality-description-2__column-1 h2 {
    margin: 0;
    font-size: 24px;
    text-align: center;
}

.functionality-description-2__column-1 h2::after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 9%;
    padding-top: 15px;
    border-bottom: 2px solid #f91942;
    transform: scaleX(1);
    transition: 0.3s ease-in-out;
}

.functionality-description-2__column-1 h2:hover::after {
    transform: scaleX(0.5);
}

.functionality-description-2__column-1 p {
    text-align: justify;
    font-size: 15px;
    margin: 20px 80px 0 80px;
    line-height: 21px;
}

.functionality-description-2__column-1 li {
    padding: 10px 0 4px 25px;
}

.functionality-description-2__column-2 {
    flex:0.4;
    width: 100%;
    height: 500px;
    background-image: url('../img/Untitled-3.svg');
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
}

.functionality-description-3 {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
}

.functionality-description-3__column-1 {
    flex: 0.4;
    height: 500px;
    background-image: url('../img/Untitled-1.svg');
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
}

.functionality-description-3__column-2 {
    flex:0.6;
    text-align: center;
}

.functionality-description-3__column-2 h2 {
    margin: 0;
    font-size: 23px;
    text-align: center;
}

.functionality-description-3__column-2 h2:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 9%;
    padding-top: 15px;
    border-bottom: 2px solid #f91942;
    transform: scaleX(1);
    transition: 0.3s ease-in-out;
}

.functionality-description-3__column-2 h2:hover::after {
    transform: scaleX(0.5);
}

.functionality-description-3__column-2 p {
    text-align: justify;
    font-size: 15px;
    margin: 20px 80px 0 80px;
    line-height: 25px;
}

@media only screen and (max-width: 1400px) {
    .functionality-description-3 {
        display: flex;
        flex-direction: column;
        padding: 0
    }

    .functionality-description-3__column-1 {
        background-image: url('../img/Untitled-1.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 910px;
    }
}

@media only screen and (max-width: 1150px) {
    .functionality-description-3__column-1 {
        background-image: url('../img/Untitled-1.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 750px;
    }
}

@media only screen and (max-width: 900px) {
    .functionality-description-3__column-1 {
        background-image: url('../img/Untitled-1.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 580px;
    }
}

@media only screen and (max-width: 750px) {
    .functionality-description-3__column-1 {
        background-image: url('../img/Untitled-1.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 500px;
    }
}

@media only screen and (max-width: 580px) {
    .functionality-description-3__column-1 {
        background-image: url('../img/Untitled-1.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 390px;
    }
}

@media only screen and (max-width: 400px) {
    .functionality-description-3__column-1 {
        background-image: url('../img/Untitled-1.svg');
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 280px;
    }
}