.contact-container {
    margin: 70px 0 0 0;
    padding: 50px 0 0 50px;
    border: 1px solid #eeeeee;
    display: flex;
    height: 250px;
    background-color: white;
}

@media screen and (max-width: 800px) {
    .contact-container {
        display: flex;
        flex-direction: column;
        min-height: 290px;
        padding-top: 20px;
        padding-left: 25px;
        background-color: white;
    }
}

.contact-container__column-1 {
    flex: 0.5;
    position: relative;
}

.contact-container__column-1 img {
    width: 250px;
}

.contact-container__reference {
    margin-top: 15px;
}

.contact-container__reference a {
    color: #f91942;
    padding: 0 13px 0 13px;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
}

@media screen and (max-width: 700px) {
    .contact-container__reference a {
        font-size: 14px;
    }
}

@media screen and (max-width: 450px) {
    .contact-container__reference a {
        font-size: 13px;
    }
}

.contact-container__column-2 {
    flex: 0.5;
    position: relative;
}

.contact-container__details h4 {
    margin: 0;
    font-size: 22px;
}

.contact-container__details p{
    font-size: 15px;
}

.contact-container__details span {
    color: #f91942;
    font-weight: bold;
}

@media screen and (max-width: 700px) {
    .contact-container__details h4 {
        font-size: 19px;
    }

    .contact-container__details p{
        font-size: 14px;
    }

    .contact-container__copyrights p {
        font-size: 15px;
    }
}

@media screen and (max-width: 450px) {
    .contact-container {
        margin: 0;
    }

    .contact-container__column-1 img {
        width: 220px;
    }

    .contact-container__details h4 {
        font-size: 18px;
    }

    .contact-container__details p{
        font-size: 13px;
    }
}

.contact-container__copyrights {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.contact-container__copyrights p {
    margin: 0;
    color: #a4a4a4;
    font-weight: bold;
}

